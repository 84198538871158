import React from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import SEO from "../components/seo";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import PageWrapper from "../PageWrapper";
import heroSvg from '../img/undraw/undraw_Mail_sent_qwwx.svg';
import Link from 'gatsby-link';

function Hero({ bgImage }){

  return (
      <>
          <div className="header-hero header-hero--index bg_cover d-lg-flex align-items-center" style={{ minHeight: "100vh" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 text-center">
                            <div className="header-hero-content">
                                <h1 className="hero-title">
                                  Thank you!
                                </h1>
                                <h5 className="text-white">Your form submission has been received.</h5>
                                <p>
                                  <Link className="mt-5 px-4 btn-cta btn btn-primary btn-lg" to="/">
                                  Back to our site
                                  </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-success-hero-image header-hero-image d-flex align-items-center">
                    <img className="p-5" src={bgImage} alt="Hero" />
                </div>
            </div>
      </>

  );

}

const ContactSuccess = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Helmet>
        {/* Slick jQuery Plugin */}
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"/>
      </Helmet>
      <Header component={<Hero bgImage={heroSvg}/>} title={siteTitle} />
      <SEO title="Automate your translation quality" />
      <Footer/>
    </PageWrapper>
  )
}

export default ContactSuccess

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
